import React from 'react';
import { Link, graphql, navigate } from "gatsby";
import Seo from "../components/seo"
import { isBrowser, isLoggedIn } from "../services/auth.js"

const WpPostTemplate = ({ data, pageContext }) => {
  if (!isBrowser()) {
    return null;
  }
  const { type } = pageContext;
  const { title, content, date } = data[type];
  var MemberOnly = null;
  var memberonly = false;
  if (type === 'wpNotice') {
    MemberOnly = data[type].MemberOnly;
    memberonly = MemberOnly.memberonly;
  }

  if(MemberOnly !== null && memberonly === true && !isLoggedIn()){
    navigate('/404');
    return null;
  }

  const returnLinkHtml = (isLoggedIn() && memberonly === true) ? 
              <Link to="/userpage/">会員ページに戻る</Link>:
              <Link to="/news_list/">一覧に戻る</Link> 

  const label = type === 'wpAssociationNotice'
    ? {
      labelId: '1',
      labelName: '協会からのお知らせ'
    }
    : {
      labelId: '3',
      labelName: isLoggedIn() && memberonly ? '会員向けお知らせ' : 'その他'
    }

  return (
    <>
      <Seo title={title} />
      <div id="pageTitle">
        <h1>お知らせ</h1>
      </div>
      <div className="breadClumb">
        <ul>
          <li>
            <Link to={"/"}>
              HOME
            </Link>
          </li>
          <li>
            <Link to={"/news_list/"}>
              お知らせ一覧
            </Link></li>
          <li>{title}</li>
        </ul>
      </div>
      <div id="main">
        <div className="contents">
          <section className="contents_block">
            <div className="news_detail-subData">
              <div className="news_detail-date">
                {date.slice(0, 10).replace(/-/g, '.')}</div>
              <div className="news_detail-category"><div className={`label_base label0${label.labelId}`}>{label.labelName}</div></div>
            </div>
            <h1 className="header01">{title}</h1>
            <div className="news_detail_inner">
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className="backToNewsList">
              {returnLinkHtml}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

// TODO: 独自の投稿タイプが追加されたらここに追加
export const query = graphql`
  query($id: String!) {
    wpNotice(id: { eq: $id }) {
      title
      content
      date
      MemberOnly {
        memberonly
      }
    }
    wpAssociationNotice(id: { eq: $id }) {
      title
      content
      date
    }
  }
`;

export default WpPostTemplate;